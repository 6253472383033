/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum BrokenGlassControlStatus {
    DONE = 'DONE',
    ONGOING = 'ONGOING',
    PENDING = 'PENDING',
}

export enum BrokenGlassControlPicturesType {
    WINDSHIELD = 'WINDSHIELD',
    REPAIR = 'REPAIR',
    OTHER = 'OTHER'
}

export interface BrokenGlassControl {
    id: number;
    status: BrokenGlassControlStatus;
    uid: string;
    insurancePolicyNumber: string | null;
    insured: string | null;
    insuranceCompanyId: number;
    insuranceCompanyName: string | null;
    controlDate: string;
    doneDate: string;
    vehicleLicensePlate: string | null;
    vehicleBrand: string | null;
    vehicleModel: string | null;
    glassProviderDistrict: string | null;
    glassProviderId: number;
    controlType: BrokenGlassControlPicturesType | null;
    checkInDate: string | null;
    checkedIn: boolean;
    checkOutDate: string | null;
    checkedOut: boolean;
}

export interface CheckInOutImages {
    id?: number;
    checkInFrontWindshieldPhotoId: number | null;
    checkInFrontRightWindshieldPhotoId: number | null;
    checkInFrontLeftWindshieldPhotoId: number | null;
    checkInSerigraphyPhotoId: number | null;
    checkInFrontBodyPhotoId: number | null;
    checkInAdditionalPhotosIds: Array<number>;
    checkOutFrontWindshieldPhotoId: number | null;
    checkOutFrontRightWindshieldPhotoId: number | null;
    checkOutFrontLeftWindshieldPhotoId: number | null;
    checkOutSerigraphyPhotoId: number | null;
    checkOutFrontBodyPhotoId: number | null;
    checkOutAdditionalPhotosIds: Array<number>;
}

export interface ApiFile {
    id: number;
    fileString: string;
    index?: number;
}

export enum ControlGlassImagesKeys {
    FrontBody = 'frontBody',
    FrontWindshield = 'frontWindshield',
    FrontRightWindshield = 'frontRightWindshield',
    FrontLeftWindshield = 'frontLeftWindshield',
    Serigraphy = 'serigraphy',
}

export interface ControlGlassImages {
    frontBody: ApiFile | null;
    frontWindshield: ApiFile | null;
    frontRightWindshield: ApiFile | null;
    frontLeftWindshield: ApiFile | null;
    serigraphy: ApiFile | null;
    additionalPhotos: Array<ApiFile>;
}

export type PhotosUploadingState<T> = {
    [K in keyof T]?: boolean;
};

export enum ImageFileType {
    FRONT_BODY = 'FRONT_BODY',
    LEFT_BODY = 'LEFT_BODY',
    RIGHT_BODY = 'RIGHT_BODY',
    BACK_BODY = 'BACK_BODY',
    CEILING = 'CEILING',
    FRONT_WINDSHIELD = 'FRONT_WINDSHIELD',
    FRONT_RIGHT_WINDSHIELD = 'FRONT_RIGHT_WINDSHIELD',
    FRONT_LEFT_WINDSHIELD= 'FRONT_LEFT_WINDSHIELD',
    LICENSE_PLATE_WINDSHIELD = 'LICENSE_PLATE_WINDSHIELD',
    INSIDE = 'INSIDE',
    IDENTIFICATION = 'IDENTIFICATION',
    DOCUMENT = 'DOCUMENT',
    INSPECTION = 'INSPECTION',
    ADDITIONAL_PHOTOS = 'ADDITIONAL_PHOTOS',
}

export interface PreInspectionImageResponse {
    id: number;
    originalFileName: string;
    mimeType: string;
    fileType: ImageFileType;
    bodyworkDamage: boolean;
    glassDamage: boolean;
}

export interface PreInspectionCompleteImagesResponse {
    additionalPhotos: PreInspectionImageResponse[];
    frontBody: PreInspectionImageResponse;
    leftBody: PreInspectionImageResponse;
    rightBody: PreInspectionImageResponse;
    backBody: PreInspectionImageResponse;
    ceiling: PreInspectionImageResponse;
    frontWindshield: PreInspectionImageResponse;
    frontRightWindshield: PreInspectionImageResponse;
    frontLeftWindshield: PreInspectionImageResponse;
    inside: PreInspectionImageResponse;
    identification: PreInspectionImageResponse;
    document: PreInspectionImageResponse;
    inspection: PreInspectionImageResponse;
}

export interface InsuranceCompany {
    createdDate: number;
    id: number;
    contact: number;
    email: string;
    inactive: boolean;
    name: string;
    role: string;
    nif: string;
    apiKey: string;
}

export enum PreInspectionsStatus {
    CANCELED = 'CANCELED',
    DONE = 'DONE',
    ONGOING = 'ONGOING',
    PENDING = 'PENDING',
    REOPENED = 'REOPENED',
}

export interface FileResponse {
    id: number;
    originalFileName: string;
    mimeType: string;
    url: string;
    createdDate: number;
    fileType: string;
    glassDamage: boolean;
    bodyworkDamage: boolean;
}

export interface Vehicle {
    bodyType: string | null;
    engineKW: string | null;
    enginePS: string | null;
    engineSize: number;
    fuelType: string | null;
    gearType: string | null;
    makeDescription: string | null;
    modelDescription: string | null;
    numberDoors: string | null;
    numberGears: string | null;
    periodOfBuild: string | null;
    priceBrutto: string | null;
    priceNetto: string | null;
    typeDescription: string | null;
    typeETGCode: string | null;
    vehType: string | null;
}

export interface VehicleInfo {
    colourCode: string;
    colourDescription: string;
    createdDate: string;
    deliveryDate: string;
    id: string;
    lastModifiedDate: string | null;
    ldtDeliveryDate: string | null;
    ldtProductionDate: string | null;
    ldtRegistrationDate: string | null;
    productionDate: string | null;
    registrationDate: string | null;
    statusCode: string | null;
    statusMessage: string | null;
    topCode: string | null;
    topDescription: string | null;
    upholsteryCode: string | null;
    upholsteryDescription: string | null;
    vehicles: Vehicle[] | null;
    voc: string | null;
    voc2: string | null;
}

export interface PreInspectionStart {
    createdDate: string;
    doneDate: string | null;
    id: number | null;
    agentAddress: string;
    agentContact: string;
    agentEmail: string;
    agentName: string;
    insuranceCompany: InsuranceCompany | null;
    insuranceExtrasAmount: number | null;
    insuranceStartDate: string;
    insuranceYearlyAmount: number | null;
    insured: string;
    insuredContact: string;
    insuredEmail: string;
    marketValue: number | null;
    mediatorNumber: string;
    observations: string;
    simulationDate: string;
    simulationNumber: string;
    sendPhotosDate: number;
    status: PreInspectionsStatus | null;
    insuranceTylaCode: string;
    uid: string;
    userToken: string;
    type: string;
    vehicleBrand: string;
    vehicleLicensePlate: string;
    vehicleModel: string;
    eurotaxTylaCode: string | null;
    images: Array<FileResponse>;
    repairCost: number | null;
    result: PreInspectionsStatus | null;
    vehicleColor: string;
    vehicleImported: boolean | null;
    vehicleManufactureDate: string;
    vehicleLicenseDate: string;
    vehicleMileage: number;
    mileageReadout: string | null;
    inspected: boolean | null;
    inspectionDate: string;
    designatedValue: string;
    eurotaxPurchaseValue: string;
    eurotaxSaleValue: string;
    glassDamageEstimate: string;
    insuranceEurotaxValue: string;
    vin: string;
    vehicleInfo?: VehicleInfo | null;
    vehiclePower: string | null;
    vehicleVersion: string | null;
    pciVehicleBrand: string | null;
    pciVehicleModel: string | null;
    vehicleCylinderVolume: string | null;
    vehicleFuelType: string | null;
}

export interface PreInspectionStartResponse {
    id: number;
    preInspection: PreInspectionStart;
    frontWindshield: PreInspectionImageResponse | null;
    frontRightWindshield: PreInspectionImageResponse | null;
    frontLeftWindshield: PreInspectionImageResponse | null;
    frontBody: PreInspectionImageResponse | null;
    rightBody: PreInspectionImageResponse | null;
    leftBody: PreInspectionImageResponse | null;
    backBody: PreInspectionImageResponse | null;
    ceiling: PreInspectionImageResponse | null;
    inside: PreInspectionImageResponse | null;
    identification: PreInspectionImageResponse | null;
    document: PreInspectionImageResponse | null;
    inspection: PreInspectionImageResponse | null;
    additionalPhotos: PreInspectionImageResponse[];
}
