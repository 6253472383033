/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedPreInspectionController, PreInspectionController } from './PreInspectionController';
import { PreInspectionGlassImages, PreInspectionCompleteImages } from '../../../reducers/types';

export interface PreInspectionContext extends Omit<InstanceType<typeof PreInspectionController>, keyof React.Component> {
    glass: PreInspectionGlassImages;
    complete: PreInspectionCompleteImages;
}

const PreInspectionContext = createContext<PreInspectionContext | null>(null);

export const PreInspectionContextProvider = PreInspectionContext.Provider;
export const PreInspectionContextConsumer = PreInspectionContext.Consumer;

export const withPreInspectionContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof PreInspectionContext>> => props => (
    <ConnectedPreInspectionController>
        <PreInspectionContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </PreInspectionContextConsumer>
    </ConnectedPreInspectionController>
    );
