/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const INDEX_ROUTE = '/st';
export const WELCOME_COMPLETE_ROUTE = '/welcome-complete';
export const WELCOME_GLASS_ROUTE = '/welcome-glass';
export const PICTURES_STEP_ROUTE = '/pictures';
export const INFORMATION_STEP_ROUTE = '/information';
export const INFORMATION_GLASS_ROUTE = '/glass-information';
export const TOKEN_EXPIRED_ROUTE = '/expired';
export const FINAL_ERROR_ROUTE = '/error';
export const FINAL_SUCCESS_ROUTE = '/success';
export const FINAL_GLASS_ERROR_ROUTE = '/glass-error';
export const FINAL_GLASS_SUCCESS_ROUTE = '/glass-success';
export const VIDEO_CALL_ROUTE = '/vc';
export const VIDEO_ROUTE = '/video';
export const VIDEO_CALL_ENDED_ROUTE = '/video-final';
export const NOT_FOUND_ROUTE = '/404';
export const DISPUTE_ROUTE = '/dispute';
export const CHECK_IN_ROUTE = '/check-in';
export const CHECK_OUT_ROUTE = '/check-out';
