/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export interface KeyedObject {
    [key: string]: any;
}

export type SelectOption = {
    value: any;
    label: any;
}

export interface MatchParams {
    id?: string;
    name?: string;
    token?: string;
    userId?: string;
}

export interface FilesUpload {
    [key: string]: File | null;
    frontBody: File | null;
    rightBody: File | null;
    leftBody: File | null;
    backBody: File | null;
    ceiling: File | null;
    frontWindshield: File | null;
    frontRightWindshield: File | null;
    frontLeftWindshield: File | null;
    inside: File | null;
    identification: File | null;
    document: File | null;
    inspection: File | null;
}

export interface PreInspection {
    uid: string;
    insuranceCompanyName: string;
    insuredEmail: string;
    simulationNumber: string;
    insured: string;
    vehicleLicensePlate: string;
    vehicleBrand: string;
    vehicleModel: string;
    type: string;
    simulationDate: string | null;
}

export interface PreInspectionFinalize {
    extras: boolean;
    extrasDescription: string;
    desiredInsuredSum: string | null;
    observations: string;
}

export enum PreInspectionType {
    COMPLETE = 'COMPLETE',
    GLASS = 'GLASS',
}

export interface HistoryState {
    callDuration?: string;
    callWasDeclined?: boolean;
    videoError?: boolean;
}

export enum GlassStatusResponse {
    INVALID = 'INVALID',
    DAMAGED = 'DAMAGED',
    OK = 'OK',
}

export enum SocketReceivedMessageType {
    INVALID_TOKEN = 'INVALID_TOKEN',
    INVALID_MESSAGE = 'INVALID_MESSAGE',
    INVALID_CALLEE = 'INVALID_CALLEE',
    INVALID_SDP = 'INVALID_SDP',
    USER_OFFLINE = 'USER_OFFLINE',
    USER_BUSY = 'USER_BUSY',
    CALL_ANSWERED = 'CALL_ANSWERED',
    CALL_DECLINED = 'CALL_DECLINED',
    INCOMING_CALL_CANCELED = 'INCOMING_CALL_CANCELED',
    ICE_CANDIDATE_FOUND = 'ICE_CANDIDATE_FOUND',
    CALL_TERMINATED = 'CALL_TERMINATED',
    TURN_CREDENTIALS = 'TURN_CREDENTIALS',
}

export enum SocketSendMessageType {
    CREATE_OFFER = 'CREATE_OFFER',
    CANCEL_OFFER = 'CANCEL_OFFER',
    OFFER_ICE_CANDIDATE = 'OFFER_ICE_CANDIDATE',
    TERMINATE_OFFER = 'TERMINATE_OFFER',
    GET_TURN_CREDENTIALS = 'GET_TURN_CREDENTIALS',
}

export interface SocketReceivedMessage {
    message: string;
    messageType: SocketReceivedMessageType;
    type: RTCSdpType;
    sdp: string;
    from: string;
    candidate: RTCIceCandidateInit;
    credential: string;
    username: string;
}

export enum PhotoAngleType {
    FRONT_BODY = 'FRONT_BODY',
    RIGHT_BODY = 'RIGHT_BODY',
    LEFT_BODY = 'LEFT_BODY',
    BACK_BODY = 'BACK_BODY',
    CEILING = 'CEILING',
    FRONT_WINDSHIELD = 'FRONT_WINDSHIELD',
    FRONT_RIGHT_WINDSHIELD = 'FRONT_RIGHT_WINDSHIELD',
    FRONT_LEFT_WINDSHIELD = 'FRONT_LEFT_WINDSHIELD'
}

export enum OriginPage {
    EDIT_PAGE = 'EDIT_PAGE',
    LIST_PAGE = 'LIST_PAGE',
}
