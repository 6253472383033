/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { merge, without } from 'lodash';
import { KeyedObject, MatchParams } from '../../../constants/misc';
import { CHECK_IN_ROUTE, NOT_FOUND_ROUTE } from '../../../constants/routes';
import {
    ApiFile,
    BrokenGlassControl,
    BrokenGlassControlPicturesType,
    BrokenGlassControlStatus,
    CheckInOutImages,
    ControlGlassImages,
    PhotosUploadingState,
} from '../../../constants/types';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import BeforeProceedingMessage from '../../elements/BeforeProceedingMessage';
import Loader from '../../elements/Loader';
import logo from '../../../assets/images/logo_horizontal.svg';
import assistLogo from '../../../assets/images/fluxe_assist_logo.svg';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import Button from '../../elements/Button';
import { GeneralContext, withGeneralContext } from '../../controllers/general/GeneralContext';
import { GlassProviderContext, withGlassProviderContext } from '../../controllers/glassProvider/GlassProviderContext';
import WindshieldUploadPhotos from '../../elements/brokenGlassControl/WindshieldUploadPhotos';
import AdditionalPhotos from '../../elements/AdditionalPhotos';
import FinalScreen from './FinalScreen';
import { filterNullish, getUrlBackToWeb, parseStringType } from '../../../utils/misc';
import { getErrorString } from '../../../utils/errors';

enum Pages {
    WELCOME = 'WELCOME',
    TYPES = 'TYPES',
    UPLOAD = 'UPLOAD',
    FINAL = 'FINAL'
}

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, GeneralContext, GlassProviderContext {}

interface OwnState {
    fetching: boolean;
    page: Pages;
    brokenGlassControl: BrokenGlassControl | null;
    controlGlassImages: ControlGlassImages;
    error: string;
    imagesUploading: PhotosUploadingState<ControlGlassImages>;
}

const initialState: OwnState = {
    fetching: true,
    page: Pages.WELCOME,
    brokenGlassControl: null,
    controlGlassImages: {
        frontBody: null,
        frontRightWindshield: null,
        frontLeftWindshield: null,
        frontWindshield: null,
        serigraphy: null,
        additionalPhotos: [],
    },
    error: '',
    imagesUploading: {},
};

class CheckInOutScreen extends Component <OwnProps, OwnState> {
    state = { ...initialState };
    
    componentDidMount(): void {
        const { history, setUserToken } = this.props;

        const token = sessionStorage.getItem('token');
        const brokenGlassControlId = sessionStorage.getItem('brokenGlassControlId');

        if (!brokenGlassControlId || !token) {
            history.push(NOT_FOUND_ROUTE);
            return;
        }

        setUserToken(token);
        this.prepare(brokenGlassControlId);
    }

    onConfirmClick = (): void => {
        const { brokenGlassControl } = this.state;
        const nextPage = brokenGlassControl?.controlType ? Pages.UPLOAD : Pages.TYPES;

        this.setState({ page: nextPage }, () => {
            if (nextPage === Pages.UPLOAD) {
                this.fetchImages();
            }
        });
    }

    onTypeClick = async (type: BrokenGlassControlPicturesType): Promise<void> => {
        const { history, changeBrokenGlassType } = this.props;
        const { brokenGlassControl } = this.state;
        if (!brokenGlassControl) return;

        if (type !== brokenGlassControl?.controlType) {
            this.setState({
                fetching: true,
            });
            
            const error = await changeBrokenGlassType(brokenGlassControl.id, type);
            if (error) {
                history.push(NOT_FOUND_ROUTE);
                return;
            }

            this.setState({
                brokenGlassControl: merge(brokenGlassControl, { controlType: type }),
                page: Pages.UPLOAD,
                fetching: false,
            }, this.fetchImages);
        } else {
            this.setState({
                page: Pages.UPLOAD,
            }, this.fetchImages);
        }
    }

    onFileDeleted = async (file: ApiFile | null, name: keyof ControlGlassImages): Promise<void> => {
        const { deleteImage } = this.props;
        const { brokenGlassControl } = this.state;

        if (!brokenGlassControl || !file) return;

        if (await deleteImage(brokenGlassControl, file.id, parseStringType(name))) {
            // todo: handle error
            return;
        }
        
        this.setState({ controlGlassImages: this.removeControlGlassImage(file, name) });
    }

    onFileSelected = async (file: File, name: keyof ControlGlassImages, additionalPhotoIndex?: number): Promise<void> => {
        const { uploadBrokenGlassImages } = this.props;
        const { brokenGlassControl, imagesUploading } = this.state;

        const { type: fileType } = file;

        if (!brokenGlassControl) return;

        if (!fileType.startsWith('image') || fileType.includes('svg')) {
            this.setState({ error: 'errorFileType' });
            return;
        }

        this.setState({
            error: '',
            imagesUploading: {
                ...imagesUploading,
                [name]: true,
            },
        });

        const data = await uploadBrokenGlassImages(brokenGlassControl, file, parseStringType(name.toString()));
        if ((data as KeyedObject)?.isError) {
            let error = 'errorServerDown';
            const errorCodeString = getErrorString((data as KeyedObject).errors?.[0]?.errorCode);

            if (errorCodeString) {
                error = errorCodeString;
            }
            this.setState(prevState => ({
                error,
                imagesUploading: {
                    ...prevState.imagesUploading,
                    [name]: false,
                },
            }));
            return;
        }

        this.setState(prevState => ({
            controlGlassImages: this.addNewControlGlassImage(file, name, data, additionalPhotoIndex || 0),
            imagesUploading: {
                ...prevState.imagesUploading,
                [name]: false,
            },
        }));
    }

    removeControlGlassImage = (file: ApiFile, name: keyof ControlGlassImages): ControlGlassImages => {
        const { controlGlassImages } = this.state;

        const newControlGlassImages = { ...controlGlassImages };
        if (name === 'additionalPhotos') {
            newControlGlassImages.additionalPhotos = without(controlGlassImages.additionalPhotos, file);
        } else {
            newControlGlassImages[name] = null;
        }

        return newControlGlassImages;
    }

    addNewControlGlassImage = (file: File, name: keyof ControlGlassImages, data: CheckInOutImages | KeyedObject, additionalPhotoIndex: number): ControlGlassImages => {
        const { controlGlassImages } = this.state;

        const newControlGlassImages: ControlGlassImages = { ...controlGlassImages };
        if (name === 'additionalPhotos') {
            newControlGlassImages.additionalPhotos = [...newControlGlassImages.additionalPhotos, { id: data.id, fileString: URL.createObjectURL(file), index: additionalPhotoIndex }];
        } else {
            newControlGlassImages[name] = { id: data.id, fileString: URL.createObjectURL(file) };
        }

        return newControlGlassImages;
    }

    fetchImages = async (): Promise<void> => {
        const { getBrokenGlassImages, history } = this.props;
        const { brokenGlassControl, page } = this.state;

        if (!brokenGlassControl || page !== Pages.UPLOAD) return;

        this.setState({ fetching: true });

        const imagesData = await getBrokenGlassImages(brokenGlassControl.id);
        if (!imagesData) {
            history.push(NOT_FOUND_ROUTE);
            return;
        }
        this.fetchAdditionalImages(imagesData);
    }

    fetchAdditionalImages = async (images: CheckInOutImages): Promise<void> => {
        const { getBrokenGlassDocument } = this.props;
        const { brokenGlassControl, controlGlassImages } = this.state;

        if (!brokenGlassControl) return;

        const imagesToFetch: (number | null)[] = [];
        const {
            checkInFrontBodyPhotoId, checkInSerigraphyPhotoId, checkInFrontRightWindshieldPhotoId, checkInFrontLeftWindshieldPhotoId,
            checkOutFrontWindshieldPhotoId, checkInAdditionalPhotosIds, checkOutAdditionalPhotosIds, checkInFrontWindshieldPhotoId,
            checkOutFrontBodyPhotoId, checkOutFrontLeftWindshieldPhotoId, checkOutFrontRightWindshieldPhotoId, checkOutSerigraphyPhotoId,
        } = images;

        switch (brokenGlassControl.status) {
            case BrokenGlassControlStatus.PENDING:
                imagesToFetch.push(
                    checkInFrontBodyPhotoId, checkInFrontRightWindshieldPhotoId, checkInFrontWindshieldPhotoId,
                    checkInFrontLeftWindshieldPhotoId, checkInSerigraphyPhotoId, ...checkInAdditionalPhotosIds,
                );
                break;
            case BrokenGlassControlStatus.ONGOING:
                imagesToFetch.push(
                    checkOutFrontBodyPhotoId, checkOutFrontRightWindshieldPhotoId, checkOutFrontWindshieldPhotoId,
                    checkOutFrontLeftWindshieldPhotoId, checkOutSerigraphyPhotoId, ...checkOutAdditionalPhotosIds,
                );
                break;
            default:
        }

        const [
            frontBodyPhoto, frontRightWindshieldPhoto, frontWindshieldPhoto,
            frontLeftWindshieldPhoto, serigraphyPhoto, ...additionalPhotos
        ] = await Promise.all(imagesToFetch.map(id => getBrokenGlassDocument(brokenGlassControl.id, id)));
        
        this.setState({
            controlGlassImages: {
                ...controlGlassImages,
                frontWindshield: frontWindshieldPhoto,
                frontLeftWindshield: frontLeftWindshieldPhoto,
                frontBody: frontBodyPhoto,
                frontRightWindshield: frontRightWindshieldPhoto,
                serigraphy: serigraphyPhoto,
                additionalPhotos: filterNullish(additionalPhotos).map((photo, i) => ({ ...photo, index: i })),
            },
            fetching: false,
        });
    }

    advanceToSuccessScreen = async (): Promise<void> => {
        const { finishCheckBrokenGlass } = this.props;
        const {
            brokenGlassControl,
            controlGlassImages: {
                frontBody, frontLeftWindshield,
                frontRightWindshield, frontWindshield, serigraphy,
            },
        } = this.state;

        if (!brokenGlassControl) return;

        this.setState({ fetching: true });

        if (brokenGlassControl.controlType === BrokenGlassControlPicturesType.WINDSHIELD) {
            if (!frontBody || !frontLeftWindshield || !frontRightWindshield || !frontWindshield || !serigraphy) {
                this.setState({
                    error: 'errorMandatoryDocumentsCheckInOut',
                    fetching: false,
                });
                return;
            }
        }

        if (!await finishCheckBrokenGlass(brokenGlassControl)) {
            this.setState({
                error: 'errorServerDown',
                fetching: false,
            });
            return;
        }

        this.setState({ fetching: false, page: Pages.FINAL });
    }

    prepare = async (brokenGlassControlId: string | number): Promise<void> => {
        const { history, getBrokenGlassControl, match } = this.props;

        this.setState({ fetching: true });

        const brokenGlassControl = await getBrokenGlassControl(Number(brokenGlassControlId));
        if (!brokenGlassControl) {
            history.push(NOT_FOUND_ROUTE);
            return;
        }

        if (match.path === CHECK_IN_ROUTE && brokenGlassControl.status === BrokenGlassControlStatus.ONGOING) {
            window.location.assign(getUrlBackToWeb(origin, brokenGlassControl.id));
        }

        this.setState({
            fetching: false,
            brokenGlassControl,
        }, this.fetchImages);
    }

    renderUploadPhotos = (): React.ReactNode => {
        const { t } = this.props;
        const {
            brokenGlassControl, controlGlassImages, error, fetching, imagesUploading,
        } = this.state;

        return (
            <div className="step-screen">
                <div className="step-screen__top-bar">
                    <img src={logo} alt="Fluxe Logo" />
                    <p className="title">{t('global.fluxeName')}</p>
                </div>
                <div className="step-screen__content">
                    <div className="title-container">
                        <h4>{t('welcome.welcomeMsg')}</h4>
                        <img src={assistLogo} alt="Fluxe Assist Logo" />
                        <h3>{t('welcome.glass')}</h3>
                        <h3 className="lowercase">{t('picturesUpload.glassControlHelpText')}</h3>
                    </div>
                    <div className="step-screen__content__middle without-border glass-control">
                        {
                            !fetching && (
                                <div className="step-screen__content__middle__pictures_container glass-control__pictures" data-testid="upload-container">
                                    {
                                        brokenGlassControl?.controlType === BrokenGlassControlPicturesType.WINDSHIELD
                                            ? (
                                                <WindshieldUploadPhotos
                                                    controlGlassImages={controlGlassImages}
                                                    onFileDeleted={this.onFileDeleted}
                                                    onFileSelected={this.onFileSelected}
                                                    uploadingImages={imagesUploading}
                                                />
                                            ) : (
                                                <AdditionalPhotos
                                                    files={controlGlassImages.additionalPhotos}
                                                    maximumFiles={5}
                                                    onFileDeleted={(file): Promise<void> => this.onFileDeleted(file, 'additionalPhotos')}
                                                    onFileSelected={(file, index): Promise<void> => this.onFileSelected(file, 'additionalPhotos', index)}
                                                />
                                            )
                                    }
                                </div>
                            )
                        }
                        {error && (
                            <div className="error">
                                <p>{t(`picturesUpload.${error}`)}</p>
                            </div>
                        )}
                        {(brokenGlassControl?.controlType === BrokenGlassControlPicturesType.OTHER || brokenGlassControl?.controlType === BrokenGlassControlPicturesType.REPAIR) && (
                            <p className="glass-control__msg">{t('brokenGlassControl.informationMsg')}</p>
                        )}
                        <div
                            className={`button-container glass-control__buttons-container ${
                                brokenGlassControl?.status === BrokenGlassControlStatus.ONGOING
                                    ? 'glass-control__buttons-container--one-button' : ''
                            } `}
                            data-testid="button-container"
                        >
                            {
                                brokenGlassControl?.status !== BrokenGlassControlStatus.ONGOING && (
                                    <Button
                                        callback={(): void => this.setState({ page: Pages.TYPES })}
                                        text={t('global.buttons.back')}
                                        styles="btn--white btn--full-width btn--with-icon--left--no-margins"
                                        icon={ICON.CHEVRON_LEFT}
                                        iconPosition="left"
                                    />
                                )
                            }
                            <Button
                                callback={(): Promise<void> => this.advanceToSuccessScreen()}
                                text={t('global.buttons.advance')}
                                styles="btn--green btn--full-width"
                                icon={ICON.CHEVRON_RIGHT}
                                iconPosition="right"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderTypeButton = (icon: ICON, text: string, type: BrokenGlassControlPicturesType): React.ReactNode => {
        return (
            <button
                type="button"
                className="btn btn--upload-photo purple glass-icon position-relative"
                onClick={(): Promise<void> => this.onTypeClick(type)}
            >
                <SvgIcon icon={icon} />
                <div className="title-top">
                    <p className="text-small">{text}</p>
                </div>
            </button>
        );
    };

    renderChooseType(): React.ReactNode {
        const { t } = this.props;

        return (
            <div className="step-screen">
                <div className="step-screen__top-bar">
                    <img src={logo} alt="Fluxe Logo" />
                    <p className="title">{t('global.fluxeName')}</p>
                </div>
                <div className="step-screen__content">
                    <div className="title-container">
                        <h4>{t('welcome.welcomeMsg')}</h4>
                        <img src={assistLogo} alt="Fluxe Assist Logo" />
                        <h3>{t('welcome.glass')}</h3>
                        <h3 className="lowercase">{t('brokenGlassControl.type.title')}</h3>
                    </div>
                    <div className="step-screen__content__middle without-border glass-control">
                        <div className="step-screen__content__middle__pictures_container">
                            <div className="row">
                                {
                                    this.renderTypeButton(
                                        ICON.FRONT_WINDSHIELD,
                                        t('brokenGlassControl.type.windshield'),
                                        BrokenGlassControlPicturesType.WINDSHIELD,
                                    )
                                }
                                {
                                    this.renderTypeButton(
                                        ICON.LEFT_BODY,
                                        t('brokenGlassControl.type.other'),
                                        BrokenGlassControlPicturesType.OTHER,
                                    )
                                }
                                {
                                    this.renderTypeButton(ICON.REPAIRS,
                                        t('brokenGlassControl.type.repairs'),
                                        BrokenGlassControlPicturesType.REPAIR)
                                }
                            </div>
                        </div>
                        <div className="button-container glass-control__buttons-container centered" data-testid="button-container">
                            <Button
                                callback={(): void => this.setState({ page: Pages.WELCOME })}
                                text={t('global.buttons.back')}
                                styles="btn--white btn--with-icon--left--no-margins"
                                icon={ICON.CHEVRON_LEFT}
                                iconPosition="left"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMainSection = (): React.ReactNode => {
        const { page, brokenGlassControl } = this.state;
        switch (page) {
            case Pages.WELCOME:
                return <BeforeProceedingMessage onConfirm={(): void => this.onConfirmClick()} />;
            case Pages.TYPES:
                return this.renderChooseType();
            case Pages.UPLOAD:
                return this.renderUploadPhotos();
            case Pages.FINAL:
                return (
                    <FinalScreen
                        isCheckOut={brokenGlassControl?.status === BrokenGlassControlStatus.ONGOING}
                        brokenGlassControlId={brokenGlassControl?.id}
                    />
                );
            default:
                return null;
        }
    }

    render(): React.ReactNode {
        const { fetching } = this.state;

        return (
            <>
                { fetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                { this.renderMainSection() }
            </>
        );
    }
}

export default withGlassProviderContext(withGeneralContext(withTranslationContext(withRouter(CheckInOutScreen))));
