/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import frontMask from '../../assets/images/frontPhotoMask.svg';
import backMask from '../../assets/images/backPhotoMask.svg';
import sideMask from '../../assets/images/sidePhotoMask.svg';
import frontWindShieldMask from '../../assets/images/frontWindShieldPhotoMask.svg';
import skewWindShieldMask from '../../assets/images/skewWindShieldPhotoMask.svg';
import Loader from './Loader';
import { PhotoAngleType } from '../../constants/misc';

interface OwnProps {
    onFileSelected: (file: File) => void;
    photoAngle: string;
    close: () => void;
}

interface OwnState {
    showMask: boolean;
}

const initialState = {
    showMask: false,
};

export class Camera extends React.Component<OwnProps, OwnState> {
    state = initialState

    videoRef = React.createRef<HTMLVideoElement>();

    canvasRef = React.createRef<HTMLCanvasElement>();
    
    componentDidMount() {
        this.getVideo();
    }

    componentWillUnmount() {
        const videoRefBeforeCleanup = this.videoRef.current;
        if (videoRefBeforeCleanup?.srcObject) {
            (videoRefBeforeCleanup.srcObject as MediaStream).getTracks().forEach(track => track.stop());
            videoRefBeforeCleanup.srcObject = null;
        }
    }
    
    getVideo = async () => {
        let stream = null;
        try {
            stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: { ideal: 'environment' },
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                },
            });
        } catch {
            return;
        }
        if (this.videoRef.current) {
            this.videoRef.current.srcObject = stream;
        }
    };

    takePhoto = () => {
        const { onFileSelected, close } = this.props;
        if (!this.videoRef.current || !this.canvasRef.current) return;

        const canvas = this.canvasRef.current;
        const video = this.videoRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        
        const canvasContext = canvas.getContext('2d');
        if (canvasContext) canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
            if (blob) onFileSelected(new File([blob], 'file.png', { type: 'image/png' }));
        });

        close();
    };

    getMask = () => {
        const { photoAngle } = this.props;
        const maskFactory = (url: string, className?: string) => {
            return (
                <React.Fragment>
                    <span className="camera-container__transparency" />
                    <div className={`camera-container__mask ${className || ''}`} style={{ backgroundImage: `url(${url})` }} />
                    <span className="camera-container__transparency" />
                </React.Fragment>
            );
        };

        switch (photoAngle) {
            case PhotoAngleType.FRONT_BODY:
                return maskFactory(frontMask);
            case PhotoAngleType.BACK_BODY:
                return maskFactory(backMask);
            case PhotoAngleType.FRONT_WINDSHIELD:
                return maskFactory(frontWindShieldMask);
            case PhotoAngleType.FRONT_RIGHT_WINDSHIELD:
                return maskFactory(skewWindShieldMask);
            case PhotoAngleType.LEFT_BODY:
                return maskFactory(sideMask);
            case PhotoAngleType.FRONT_LEFT_WINDSHIELD:
                return maskFactory(skewWindShieldMask, 'camera-container__mask--mirrored');
            case PhotoAngleType.RIGHT_BODY:
                return maskFactory(sideMask, 'camera-container__mask--mirrored');
            default:
                return <React.Fragment />;
        }
    };

    render() {
        const { photoAngle, close } = this.props;
        const { showMask } = this.state;
        const sideBody = photoAngle === PhotoAngleType.LEFT_BODY || photoAngle === PhotoAngleType.RIGHT_BODY;
        const frontWindShield = photoAngle === PhotoAngleType.FRONT_WINDSHIELD;
        const skewWindShield = photoAngle === PhotoAngleType.FRONT_RIGHT_WINDSHIELD || photoAngle === PhotoAngleType.FRONT_LEFT_WINDSHIELD;

        return (
            <>
                {!showMask && (
                <div className="loader-wrapper">
                    <Loader />
                </div>
                )}
                <div className={`camera-container ${showMask ? '' : 'camera-container--hidden'} ${frontWindShield ? 'camera-container--front-windshield' : ''} ${sideBody ? 'camera-container--side' : ''}  ${skewWindShield ? 'camera-container--skew-windshield' : ''}`}>
                    <canvas ref={this.canvasRef} />
                    { this.getMask() }
                    <video autoPlay playsInline ref={this.videoRef} onPlay={() => this.setState({ showMask: true })} />
                    <button className="camera-container__close-button" type="button" onClick={close}>
                    &#x2715;
                    </button>
                    <button className="camera-container__photo-button" type="button" onClick={this.takePhoto}>
                        <div className="camera-container__photo-button__circle" />
                        <div className="camera-container__photo-button__ring" />
                    </button>
                </div>
            </>
        );
    }
}
