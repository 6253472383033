export interface IError {
    typeOfViolation: string;
    errorCode?: number;
    min?: number;
    max?: number;
    size?: number;
}

export interface IFormError {
    [key: string]: IError[] | null;
}

const NOT_BLANK = 'NotBlank';
const NOT_EMPTY = 'NotEmpty';
const MIN = 'Min';
const MAX = 'Max';
const REGEX = 'Regex';
const MIN_LENGTH = 'MinLength';

const decimalNumberRegex = /^[0-9]+[.]?[0-9]{0,2}$/;

export const VALIDATIONS = {
    PRE_INSPECTION_FINALIZE: {
        desiredInsuredSum: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
    },
};

export const validateField = (fieldName: string, fieldValue: any, formValidations: { [key: string]: any }) => {
    let errors: IError[] | null = null;

    if (!formValidations[fieldName]) return errors;

    const {
        validations, regex, max, min,
    } = formValidations[fieldName];

    if (validations.includes(NOT_BLANK)) {
        if (fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === '') {
            return [{ typeOfViolation: 'NotBlank' }];
        }
    }
    
    if (validations.includes(NOT_EMPTY)) {
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            return [{ typeOfViolation: 'NotNull' }];
        }
    }

    if (validations.includes(MAX)) {
        if (fieldValue && fieldValue > max) {
            errors = [
                {
                    typeOfViolation: 'Max',
                    max,
                },
            ];
        }
    }

    if (validations.includes(REGEX)) {
        if (fieldValue && String(fieldValue) !== '' && !regex.test(fieldValue)) {
            errors = [{ typeOfViolation: 'Pattern' }];
        }
    }

    if (validations.includes(MIN)) {
        if (fieldValue !== undefined && Number(fieldValue) < min) {
            errors = [
                {
                    typeOfViolation: 'Min',
                    min,
                },
            ];
        }
    }

    if (validations.includes(MIN_LENGTH)) {
        if (fieldValue !== undefined && String(fieldValue).length < min) {
            errors = [
                {
                    typeOfViolation: 'MinLength',
                    min,
                },
            ];
        }
    }

    return errors;
};

export const getFormErrors: Function = (data: {[key: string]: any}, formValidations: { [key: string]: any }) => {
    let errors: IFormError | null = {};

    Object.keys(data).forEach(field => {
        const { [field]: fieldValue } = data;

        if (field in formValidations) {
            const { manual } = formValidations[field];

            if (!manual && errors) {
                errors[field] = validateField(
                    field,
                    fieldValue,
                    formValidations,
                );

                if (!errors[field]) delete errors[field];
            }
        }
    });

    if (Object.keys(errors).length === 0) errors = null;
    return errors;
};
